import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Odit } from '../types';
import { useStore } from '../Store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    smaller: {
      padding: 2,
    },
    
  })
);

interface Props {
  data: Odit[];
  onClick?: (order_id: string) => void;
}

const HistoryList: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Table size="small" className={classes.smaller}>
        <TableHead>
          <TableRow>
            <TableCell>Tarikh</TableCell>
            <TableCell>No Odit</TableCell>
            <TableCell>No Akaun</TableCell>
            <TableCell>Tahun/Bulan</TableCell>
            <TableCell>Jumlah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(item => (
            <TableRow key={item.noodit}>
              <TableCell>
                {item.tarikh.replace(' +0800 UTC+8', '')}
              </TableCell>
              <TableCell>
                {item.noodit}
              </TableCell>
              <TableCell>
                {item.noakaun}
              </TableCell>
              <TableCell>
                {item.tahun} {item.bulan}
              </TableCell>
              <TableCell>
                {item.jumlah}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default HistoryList;
