import React, { useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Search from '@material-ui/icons/Search';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Loading from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Odit } from '../types';
import HistoryList from './HistoryList';
import moment from 'moment';
import { useDebounce } from 'use-lodash-debounce';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    datepicker: {
      marginRight: theme.spacing(10),
      marginTop: theme.spacing(0),
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    checkbox: {
      marginLeft: theme.spacing(2),
    },
    select: {
      minWidth: theme.spacing(30),
      marginLeft: theme.spacing(2),
    },
    orderID: {
      marginTop: theme.spacing(0),
      marginRight: theme.spacing(2),
    },
    divider: {
      backgroundColor: '#fff',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

interface Parameters {
  start: string;
  end: string;
  // seller_order_no: string;
  // agency: string;
  is_approved: boolean;
  is_download: boolean;
  format?: string;
}

interface State {
  nama: string;
  nokp: string;
  is_loading: boolean;
  data: Odit[];
}

interface Props {
  // onDownload?: () => void;
  // isLoading?: boolean;
  // onClick?: (args: Parameters) => void;
  // agencies: Agency[];
  // initialParam: Parameters;
}

const HistoryForm: React.FC<Props> = props => {
  const classes = useStyles();
  
  const [startDate, setStartDate] = React.useState<string>(
    // today.format('YYYY/MM/DD')
    // props.initialParam.start
  );
  const [endDate, setEndDate] = React.useState<string>(
    // tomorrow.format('YYYY/MM/DD')
    // props.initialParam.end
  );

  const [nokp, setnokp] = React.useState<string>('');
  const [nama, setNama] = React.useState<string>('');
  const [data, setData] = React.useState<Odit[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  // props.initialParam
  const [state, setState] = React.useState<State>({
    nama: '',
    nokp: '',
    is_loading: false,
    data: []
  });

  const progress = isLoading ? (
    <Loading size={24} className={classes.buttonProgress} />
  ) : null;

  const onChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setApproved(event.target.checked);
  };

  const onSearch = () => {
    // props.onClick({
    //   start: startDate,
    //   end: endDate,
    //   seller_order_no: sellerOrderNo,
    //   agency: selected,
    //   is_approved: isApproved,
    //   is_download: false,
    // });
    setState({
      nama: nama,
      nokp: nokp,
      is_loading: true,
      data: [],
    });
  };

  const onDownload = (format:string) => {
    // props.onClick({
    //   start: startDate,
    //   end: endDate,
    //   seller_order_no: sellerOrderNo,
    //   agency: selected,
    //   is_approved: isApproved,
    //   is_download: true,
    //   format: format,
    // });
  };
  const debouncedState = useDebounce(state, 200);

  useEffect(() => {
    const fetchList = async () => {
      const {
        nama,
        nokp,
        is_loading
      } = state;
      setIsLoading(true);
      try {
        const config: AxiosRequestConfig = {
          method: 'get',
          url: '/api/zakat/history',
          params: {
            noakaun: nokp,
            nama: nama,
          }          
        };

        const resp = await axios(config);
        if (resp.status === 200) {
          if (resp.data) {
            setData(resp.data);
          }          
        }
      } catch (err) {
        console.log(err);
      }

      setTimeout(() => setIsLoading(false), 150);
    };

    fetchList();
  }, [debouncedState]);

  const normalizekp = (s:string):string => {
    let out = s.toUpperCase();
    out = out.trim();
    out = out.replace(/-/g,'');

    if (out.length > 6) {
      out = out.slice(0,6) + '-' + out.slice(6)
    }
    if (out.length > 9) {
      out = out.slice(0,9) + '-' + out.slice(9);
    }

    return out;
  }

  // const onDateRangeChange = (start: string | null, end: string | null) => {
  //   if (start !== null && end !== null) {
  //     setStartDate(start);
  //     setEndDate(end);
  //     // props.onClick({
  //     //   start: start,
  //     //   end: end,
  //     //   seller_order_no: sellerOrderNo,
  //     //   agency: selected,
  //     //   is_approved: isApproved,
  //     //   is_download: false,
  //     // });
  //   }
  // };

  // React.useEffect(() => {
  //   // props.onClick({
  //   //   start: startDate,
  //   //   end: endDate,
  //   //   seller_order_no: sellerOrderNo,
  //   //   agency: selected,
  //   //   is_approved: isApproved,
  //   //   is_download: false,
  //   // });
  // }, [isApproved]);

  return (
    <React.Fragment>
    <div className={classes.root}>
      <h3>
        Carian Bayaran Zakat
      </h3>
      <TextField
        className={classes.orderID}
        style={{ marginRight: 0 }}
        placeholder="No Kad Pengenalan"
        margin="normal"
        onChange={e => {
          setnokp(normalizekp(e.target.value))
        }}
        value={nokp}
        variant="outlined"
      />
      <TextField
        className={classes.orderID}
        style={{ marginRight: 0 }}
        placeholder="Nama"
        margin="normal"
        onChange={e => {
          setNama(e.target.value);
        }}
        value={nama}
        variant="outlined"
            />

      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={isLoading}
        onClick={onSearch}
      >
        {progress}
        Search <Search className={classes.rightIcon} />
      </Button>
    </div>
    <HistoryList data={data} />
    </React.Fragment>
  );
};

export default HistoryForm;
